import React, { useMemo, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Spinner } from 'reactstrap';
import { DataSource } from '@elit-integration/dtos';

import axios, { AxiosResponse } from 'axios';
import { useAsyncTaskAxios } from 'react-hooks-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

export interface FeedRowProps {
  feed: DataSource;
  onDelete: (feedId: string) => void;
}

export const FeedRow: React.FC<FeedRowProps> = ({ feed, onDelete }): JSX.Element => {
  const { id, name, defaultAccountId, defaultModelName } = feed;
  const deleteFeedMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/feeds/${id}`,
      method: 'DELETE',
    };
  }, [id]);

  const deleteFeedTask = useAsyncTaskAxios<AxiosResponse<void>>(axios, deleteFeedMemo);

  useEffect(() => {
    if (deleteFeedTask.result) {
      onDelete(id);
    }
  }, [onDelete, deleteFeedTask.result, id]);

  return (
    <Card className="transition-none bg-light border-0 rounded-0" data-testid={`job-${id}`}>
      <CardBody className="px-42 pt-42 pb-4">
        <Row className="align-items-center">
          <Col className="text-dark font-weight-bold ">
            <div className="h5 mb-0 font-weight-bold ">{name}</div>
            {defaultAccountId && (
              <div className="text-secondary">
                Account Id:
                {defaultAccountId}
                {' - '}
                {defaultModelName}
              </div>
            )}
            {!defaultAccountId && (
              <div className="text-danger">
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                No account configured - messages for this feed will not be processed
              </div>
            )}
          </Col>
          <Col xs="auto">
            {deleteFeedTask.error && (
              <FontAwesomeIcon icon={faExclamationTriangle} title="Failed to delete feed" />
            )}
            {deleteFeedTask.pending && deleteFeedTask.started && <Spinner size="sm" />}
            {!deleteFeedTask.started && (
              <Button
                color="link"
                onClick={() => {
                  deleteFeedTask.start();
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
