import React, { useMemo, useState, useEffect } from 'react';
import { Container, Spinner, Row, Col, Button } from 'reactstrap';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import axios, { AxiosResponse } from 'axios';
import { ElitAccount } from '@elit-integration/dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { findIndex } from 'lodash';

import { Link } from 'react-router-dom';
import { SimpleMessage } from './simple-message/simple-message';

import { useAuth0 } from '../auth';
import { AccountRow } from './account-row';

export const Accounts: React.FC = (): JSX.Element => {
  const { user } = useAuth0();
  const [accounts, setAccounts] = useState<ElitAccount[]>([]);
  const getAccountsMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/accounts`,
    };
  }, []);

  const getAccountsTask = useAsyncTaskAxios<AxiosResponse<ElitAccount[]>>(axios, getAccountsMemo);

  useAsyncRun(getAccountsTask);

  useEffect(() => {
    if (getAccountsTask.result) {
      setAccounts(getAccountsTask.result.data);
    }
  }, [getAccountsTask.result]);
  if (!user) {
    return <></>;
  }
  return (
    <>
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row className="mb-1 align-items-center">
            <Col lg={12}>
              <h3 className="mb-0">Accounts</h3>
            </Col>
            <Col className="text-right">
              <Button color="primary" tag={Link} to="/accounts/add">
                Add Account
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        {getAccountsTask.pending && (
          <SimpleMessage
            className="mb-5"
            icon={
              <Spinner
                role="progressbar"
                color="primary"
                className="d-block mx-auto"
                style={{ width: '3rem', height: '3rem' }}
              />
            }
            title="Retrieving accounts..."
          />
        )}
        {getAccountsTask.result && accounts.length === 0 && (
          <SimpleMessage
            className="mb-5"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
            title="No accounts configured"
          />
        )}
        {getAccountsTask.result && accounts.length > 0 && (
          <>
            {accounts.map((x) => (
              <AccountRow
                account={x}
                onDelete={(deletedId: string) => {
                  const index = findIndex(accounts, (y) => y.id === deletedId);
                  setAccounts([...accounts.slice(0, index), ...accounts.slice(index + 1)]);
                }}
              />
            ))}
          </>
        )}
      </Container>
    </>
  );
};
