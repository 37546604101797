import React, { useMemo, useState, useEffect } from 'react';
import { Container, Spinner, Row, Col, Button } from 'reactstrap';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import axios, { AxiosResponse } from 'axios';
import { TextJob, DataSource, ListPage } from '@elit-integration/dtos';
import { Link, useLocation } from 'react-router-dom';
import querystring from 'querystring';
import { InfiniteScroll } from '@simbathesailor/react-infinite-scroll';
import { SimpleMessage } from './simple-message/simple-message';
import { SimpleErrorMessage } from './simple-message/simple-error-message';
import { JobRow } from './job-row';
import { TabList } from './tabs/tab-list';
import { Tab } from './tabs/subcomponents/tab';
import { Loading } from './loading';
import { TabContentLabel } from './tab-content-label';

export const JobList: React.FC = (): JSX.Element => {
  const location = useLocation();

  const selectedSource = useMemo<string | undefined>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?') ? location.search.slice(1) : location.search
    );
    if (!qs.source) {
      return undefined;
    }
    if (Array.isArray(qs.source)) {
      return qs.source[0];
    }
    return qs.source;
  }, [location]);
  const getDatasourcesMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/datasources`,
    };
  }, []);
  const getDatasourcesTask = useAsyncTaskAxios<AxiosResponse<DataSource[]>>(
    axios,
    getDatasourcesMemo
  );
  const [jobs, setJobs] = useState<TextJob[]>([]);
  const [nextPage, setNextPage] = useState<string | undefined>(undefined);
  const [allComplete, setAllComplete] = useState<boolean>(false);
  const getJobsMemo = useMemo(() => {
    let baseUrl = `${process.env.REACT_APP_APIDOMAIN}/jobs`;
    if (selectedSource || nextPage) {
      baseUrl += '?';
    }
    if (selectedSource) {
      baseUrl += `source=${selectedSource}`;
      if (nextPage) {
        baseUrl += '&';
      }
    }
    if (nextPage) {
      baseUrl += `pageRef=${nextPage}`;
    }
    return {
      url: baseUrl,
    };
  }, [selectedSource, nextPage]);

  const getJobsTasks = useAsyncTaskAxios<AxiosResponse<ListPage<TextJob>>>(axios, getJobsMemo);

  useAsyncRun(getDatasourcesTask);
  useAsyncRun(getJobsTasks);
  useEffect(() => {
    setJobs([]);
    setAllComplete(false);
    setNextPage(undefined);
  }, [selectedSource]);

  useEffect(() => {
    if (getJobsTasks.result) {
      setJobs([...jobs, ...getJobsTasks.result.data.page]);
      if (!getJobsTasks.result.data.pageRef) {
        setAllComplete(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getJobsTasks.result]);

  const callbackForInfiniteScroll = React.useCallback(
    (isVisible) => {
      if (isVisible) {
        if (getJobsTasks.result) {
          setNextPage(getJobsTasks.result.data.pageRef);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nextPage, getJobsTasks.result]
  );

  return (
    <>
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row className="mb-1 align-items-center">
            <Col>
              <h3 className="mb-0">Job List</h3>
            </Col>
            <Col xs="auto" className="text-right">
              <Button color="primary" tag={Link} to="/submit">
                Submit Test
              </Button>
            </Col>
            <Col xs="auto" className="text-right">
              <Button color="primary" tag={Link} to="/upload-csv">
                Upload CSV
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        {getDatasourcesTask.result && getDatasourcesTask.result.data.length > 0 && (
          <Container className="mb-5">
            <TabList>
              <Tab autoSize to="/">
                <TabContentLabel label="My jobs" isActive={selectedSource === undefined} />
              </Tab>
              {getDatasourcesTask.result.data.map((x) => (
                <Tab key={x.id} autoSize to={`/?source=${x.name}`}>
                  <TabContentLabel label={x.name} isActive={selectedSource === x.name} />
                </Tab>
              ))}
            </TabList>
          </Container>
        )}
        {getJobsTasks.pending && (
          <SimpleMessage
            className="mb-5"
            icon={
              <Spinner
                role="progressbar"
                color="primary"
                className="d-block mx-auto"
                style={{ width: '3rem', height: '3rem' }}
              />
            }
            title="Retrieving jobs..."
          />
        )}

        {getJobsTasks.result && jobs.length === 0 && <SimpleMessage title="No Jobs found" />}
        <InfiniteScroll
          whenInfiniteScroll={!allComplete && !getJobsTasks.error}
          callback={callbackForInfiniteScroll}
          LoadMoreComponent={<Loading className="my-5 d-block mx-auto" />}
        >
          {jobs &&
            jobs.map((x) => {
              return (
                <ScrollRow
                  key={x.id}
                  id={x.id}
                  updatedDate={x.updatedDate}
                  dateSat={x.dateSat}
                  sourceSystemId={x.sourceSystemId}
                  requests={x.requests}
                  averageScore={x.averageScore}
                  testInstance={x.testInstance}
                  candidate={x.candidate}
                  textsCompleted={x.textsCompleted}
                  textsSubmitted={x.textsSubmitted}
                />
              );
            })}
        </InfiniteScroll>
        {getJobsTasks.error && (
          <SimpleErrorMessage title="Failed to get list of jobs" allowPageRefresh />
        )}
      </Container>
    </>
  );
};
const ScrollRow = React.forwardRef<HTMLDivElement, TextJob>((props, ref) => {
  const {
    id,
    updatedDate,
    dateSat,
    sourceSystemId,
    requests,
    testInstance,
    candidate,
    textsCompleted,
    averageScore,
    textsSubmitted,
  } = props;

  return (
    <div ref={ref}>
      <Row className="mb-4">
        <Col>
          <JobRow
            id={id}
            updatedDate={updatedDate}
            dateSat={dateSat}
            sourceSystemId={sourceSystemId}
            requests={requests}
            testInstance={testInstance}
            averageScore={averageScore}
            candidate={candidate}
            textsCompleted={textsCompleted}
            textsSubmitted={textsSubmitted}
          />
        </Col>
      </Row>
    </div>
  );
});
