import React, { useMemo } from 'react';
import { Container, Spinner, Row, Col } from 'reactstrap';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { TextJob, TextItem } from '@elit-integration/dtos';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faHourglass,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons';

import { isNotFound } from './axios-error-helpers';
import { SimpleErrorMessage } from './simple-message/simple-error-message';
import TextRow from './text-row';

import { SimpleMessage } from './simple-message/simple-message';

export const JobPage: React.FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const getJobMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/jobs/${id}`,
    };
  }, [id]);

  const getJobTask = useAsyncTaskAxios<AxiosResponse<TextJob>>(axios, getJobMemo);

  useAsyncRun(id && getJobTask);

  const getRequestsForJobMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/jobs/${id}/requests`,
    };
  }, [id]);

  const getRequestsForJobTask = useAsyncTaskAxios<AxiosResponse<TextItem[]>>(
    axios,
    getRequestsForJobMemo
  );

  useAsyncRun(id && getRequestsForJobTask);

  const jobNotFound = useMemo(() => {
    if (!getJobTask.error) {
      return false;
    }
    return isNotFound((getJobTask.error as AxiosError).response);
  }, [getJobTask.error]);

  return (
    <>
      {getJobTask.pending && (
        <SimpleMessage
          className="mb-5"
          icon={
            <Spinner
              role="progressbar"
              color="primary"
              className="d-block mx-auto"
              style={{ width: '3rem', height: '3rem' }}
            />
          }
          title="Retrieving job..."
        />
      )}
      {jobNotFound && (
        <SimpleMessage
          className="mb-5"
          icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          title="No job with this id found"
        />
      )}
      {getJobTask.result && (
        <>
          <div className="bg-light py-45 px-3 mb-5">
            <Container>
              <Row className="mb-1 align-items-center">
                <Col xs="auto" className="h5 mb-0">
                  <FontAwesomeIcon
                    fixedWidth
                    icon={
                      getJobTask.result.data.textsCompleted ===
                      getJobTask.result.data.textsSubmitted
                        ? faCheckCircle
                        : faHourglass
                    }
                  />
                </Col>
                <Col lg={12}>
                  <h3>{getJobTask.result.data.id}</h3>
                  <h5>{getJobTask.result.data.candidate.id}</h5>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            {getRequestsForJobTask.pending && (
              <SimpleMessage
                className="mb-5"
                icon={
                  <Spinner
                    role="progressbar"
                    color="primary"
                    className="d-block mx-auto"
                    style={{ width: '3rem', height: '3rem' }}
                  />
                }
                title="Retrieving requests for job..."
              />
            )}
            {getRequestsForJobTask.error && (
              <SimpleErrorMessage title="Failed to get list of requests" allowPageRefresh />
            )}
            {getRequestsForJobTask.result && getRequestsForJobTask.result.data.length === 0 && (
              <SimpleMessage title="No Requests found" />
            )}
            {getRequestsForJobTask.result &&
              getRequestsForJobTask.result.data.map((x) => (
                <TextRow
                  className="mb-4"
                  allwaysShow={getRequestsForJobTask.result.data.length === 1}
                  textRequest={x}
                />
              ))}
          </Container>
        </>
      )}
    </>
  );
};
