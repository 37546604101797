import React, { FC, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import querystring from 'querystring';

import { Container, Button } from 'reactstrap';
import { useAuth0 } from '../auth';
import four0fourImg from '../assets/404-serious.svg';
import { Loading } from './loading';

import { InlineErrorMessage } from './simple-message/inline-error-message';

const PrivateRoute: FC<{ roles?: string[] }> = ({ children, roles }): JSX.Element => {
  const { isAuthenticated, loading, user, loginWithRedirect } = useAuth0();
  const location = useLocation();

  const isError = useMemo<{ error: string; errorDescription: string } | undefined>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?') ? location.search.slice(1) : location.search
    );

    if (qs.error) {
      return {
        error: qs.error as string,
        errorDescription: qs.error_description as string,
      };
    }
    return undefined;
  }, [location]);

  if (loading) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    );
  }

  if (!isAuthenticated) {
    return (
      <>
        <Container className="mt-5 text-center">
          <Button
            color="primary"
            onClick={() => {
              loginWithRedirect({
                redirect_uri: window.location.origin,
                appState: { targetUrl: window.location.pathname },
              });
            }}
          >
            Log in
          </Button>
          {isError && (
            <InlineErrorMessage
              className="mt-4"
              title={isError.error === 'access_denied' ? 'Access Denied' : 'Something went wrong'}
              message={isError.errorDescription || ''}
            />
          )}
        </Container>
      </>
    );
  }
  if (
    roles &&
    roles.length > 0 &&
    user &&
    user.roles.filter((x) => roles.includes(x)).length === 0
  ) {
    return (
      <Container>
        <div className="mt-5 bg-light p-5 mb-5 rounded text-center">
          <img src={four0fourImg} alt="not found" />
        </div>
        <div className="text-center">
          Please
          <a href="/">
            <b> go to homepage</b>
          </a>
          , and if the problem persists contact your system administrator
        </div>
      </Container>
    );
  }
  return <>{children}</>;
};

export default PrivateRoute;
