/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
  Container,
  Spinner,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Alert,
  CardBody,
  Card,
} from 'reactstrap';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import Dropzone, { FileRejection } from 'react-dropzone';
import axios, { AxiosResponse } from 'axios';
import { CsvSubmissionItem, CsvJob, ElitAccount } from '@elit-integration/dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import FormData from 'form-data';
import { SimpleMessage } from './simple-message/simple-message';
import { InlineErrorMessageNoBorder } from './simple-message/inline-error-message';
import { useAuth0 } from '../auth';

export const UploadCsv: React.FC = (): JSX.Element => {
  const { user } = useAuth0();
  const [formRequest, setFormRequest] = useState<CsvSubmissionItem>({
    sourceId: '',
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileErrorMsg, setSelectedFileErrorMsg] = useState<string | null>(null);
  const getAccountsMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/accounts`,
    };
  }, []);

  const postCsvMemo = useMemo(() => {
    return {
      method: 'POST',
      url: `${process.env.REACT_APP_APIDOMAIN}/csv`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  }, []);

  const getAccountsTask = useAsyncTaskAxios<AxiosResponse<ElitAccount[]>>(axios, getAccountsMemo);

  const postCsvTask = useAsyncTaskAxios<AxiosResponse<CsvJob>>(axios, postCsvMemo);

  useAsyncRun(getAccountsTask);

  useEffect(() => {
    // clear the form
    setFormRequest({
      sourceId: '',
    });
  }, [postCsvTask.result]);

  const apiVersion = useMemo(() => {
    if (getAccountsTask.result && !formRequest.model && getAccountsTask.result.data.length > 0) {
      setFormRequest({ ...formRequest, model: getAccountsTask.result.data[0].id });
    }
    if (getAccountsTask.result) {
      const filtered = getAccountsTask.result.data.filter((x) => x.id === formRequest.model);
      if (filtered.length === 1) {
        return filtered[0].apiVersion;
      }
    }
    return '';
  }, [formRequest, getAccountsTask.result]);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
      setSelectedFileErrorMsg(null);
      // console.log(acceptedFiles);
    } else if (rejectedFiles.length > 0) {
      setSelectedFileErrorMsg(rejectedFiles[0].errors[0].message);
      // console.log(rejectedFiles);
    }
  }, []);

  if (!user) {
    return <></>;
  }
  return (
    <>
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row className="mb-1">
            <Col lg={12}>
              <h3>Upload CSV</h3>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        {getAccountsTask.pending && (
          <SimpleMessage
            className="mb-5"
            icon={
              <Spinner
                role="progressbar"
                color="primary"
                className="d-block mx-auto"
                style={{ width: '3rem', height: '3rem' }}
              />
            }
            title="Retrieving config..."
          />
        )}
        {getAccountsTask.result && getAccountsTask.result.data.length === 0 && (
          <SimpleMessage
            className="mb-5"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
            title="No accounts configured"
          />
        )}
        {getAccountsTask.result && getAccountsTask.result.data.length > 0 && (
          <>
            <FormGroup>
              <Label for="modelSelect">Model</Label>
              <Input
                type="select"
                name="select"
                id="modelSelect"
                value={formRequest.model}
                onChange={(e) => {
                  setFormRequest({ ...formRequest, model: e.target.value });
                }}
              >
                {getAccountsTask.result.data.map((x) => (
                  <option key={x.id} label={x.modelName}>
                    {x.id}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="aipVersionSelect">Api Version</Label>
              <div>{apiVersion}</div>
            </FormGroup>
            {selectedFile == null && (
              <Dropzone accept=".csv" onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} style={{ borderStyle: 'dashed', borderWidth: 1 }}>
                      <input {...getInputProps()} />
                      <p>
                        <i>Drag CSV files here or click to browse.</i>
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
            {selectedFile != null && (
              <Card>
                <CardBody>
                  <Row>
                    <Col>{selectedFile.name}</Col>
                    <Col xs="auto">
                      <Button close onClick={() => setSelectedFile(null)} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
            {selectedFileErrorMsg != null && (
              <InlineErrorMessageNoBorder
                title="Failed to select CSV file"
                message={selectedFileErrorMsg}
              />
            )}
            {postCsvTask.result && (
              <Alert color="primary" className="mt-4">
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                {'CSV file submitted, submit another or '}
                <Link to="/">go back to the job list</Link>
              </Alert>
            )}
            <Row className="align-items-center mt-5">
              <Col xs="auto">
                <Button
                  color="primary"
                  disabled={
                    (postCsvTask.started && postCsvTask.pending) ||
                    selectedFile == null ||
                    selectedFileErrorMsg != null
                  }
                  onClick={() => {
                    const form = new FormData();

                    form.append('model', formRequest.model);
                    form.append('uploadFile', selectedFile);

                    postCsvTask.start({
                      data: form,
                    });
                  }}
                >
                  {postCsvTask.started && postCsvTask.pending && (
                    <Spinner size="sm" className="mr-2" />
                  )}
                  Upload CSV
                </Button>
              </Col>
              <Col>
                {postCsvTask.error && (
                  <InlineErrorMessageNoBorder
                    title="Failed to upload CSV file"
                    message="Please try again and if the problem persists please consult your system administrator"
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};
