import React, { FC } from 'react';

import { Route, Switch } from 'react-router';
import { NavBar } from './navbar';
import { HomePage } from './home';
import PrivateRoute from './private-route';
import { Submit } from './submit';
import { UploadCsv } from './upload-csv';
import { NotFound } from './not-found';
import { JobPage } from './job-page';
import { AccountForm } from './account-form';
import { Accounts } from './accounts';
import { Feeds } from './feeds';
import { FeedForm } from './feed-form';

export const RoutesWithHeader: FC = (): JSX.Element => {
  return (
    <div style={{ marginBottom: '5rem' }}>
      <NavBar />

      <Switch>
        <Route exact path="/">
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        </Route>
        <Route exact path="/jobs/:id">
          <PrivateRoute>
            <JobPage />
          </PrivateRoute>
        </Route>
        <Route exact path="/submit">
          <PrivateRoute>
            <Submit />
          </PrivateRoute>
        </Route>
        <Route exact path="/upload-csv">
          <PrivateRoute>
            <UploadCsv />
          </PrivateRoute>
        </Route>
        <Route exact path="/accounts">
          <PrivateRoute>
            <Accounts />
          </PrivateRoute>
        </Route>
        <Route exact path="/feeds">
          <PrivateRoute>
            <Feeds />
          </PrivateRoute>
        </Route>
        <Route exact path="/accounts/add">
          <PrivateRoute>
            <AccountForm />
          </PrivateRoute>
        </Route>
        <Route exact path="/feeds/add">
          <PrivateRoute>
            <FeedForm />
          </PrivateRoute>
        </Route>
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};
