import React, { useMemo, useState, useEffect } from 'react';
import { Container, Spinner, FormGroup, Label, Input, Button, Row, Col, Alert } from 'reactstrap';
import { useAsyncTaskAxios } from 'react-hooks-async';
import axios, { AxiosResponse } from 'axios';
import { TextJob, ElitAccount } from '@elit-integration/dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { InlineErrorMessageNoBorder } from './simple-message/inline-error-message';
import { useAuth0 } from '../auth';

export const AccountForm: React.FC = (): JSX.Element => {
  const { user } = useAuth0();
  const [formRequest, setFormRequest] = useState<ElitAccount>({
    id: '',
    name: '',
    modelName: '',
    apiVersion: '',
    endpoint: '',
    apiToken: '',
  });

  const putAccountMemo = useMemo(() => {
    return {
      method: 'PUT',
      url: `${process.env.REACT_APP_APIDOMAIN}/accounts`,
    };
  }, []);

  const putAccountTask = useAsyncTaskAxios<AxiosResponse<TextJob>>(axios, putAccountMemo);

  useEffect(() => {
    // clear the form
    setFormRequest({
      id: '',
      name: '',
      modelName: '',
      apiVersion: '',
      endpoint: '',
      apiToken: '',
    });
  }, [putAccountTask.result]);

  if (!user) {
    return <></>;
  }
  return (
    <>
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row className="mb-1">
            <Col lg={12}>
              <h3>Add Account</h3>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <FormGroup>
          <Label for="idInput">Account Id</Label>
          <Input
            type="text"
            name="idInput"
            id="idInput"
            value={formRequest.id}
            onChange={(e) => {
              setFormRequest({ ...formRequest, id: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="nameInput">Name</Label>
          <Input
            type="text"
            name="nameInput"
            id="nameInput"
            value={formRequest.name}
            onChange={(e) => {
              setFormRequest({ ...formRequest, name: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="modelNameInput">Model Name</Label>
          <Input
            type="text"
            name="modelNameInput"
            id="modelNameInput"
            value={formRequest.modelName}
            onChange={(e) => {
              setFormRequest({ ...formRequest, modelName: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="endpointInput">Endpoint</Label>
          <Input
            type="text"
            name="endpointInput"
            id="endpointInput"
            value={formRequest.endpoint}
            onChange={(e) => {
              setFormRequest({ ...formRequest, endpoint: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="apiVersionInput">Api Version</Label>
          <Input
            type="text"
            name="apiVersionInput"
            id="apiVersionInput"
            value={formRequest.apiVersion}
            onChange={(e) => {
              setFormRequest({ ...formRequest, apiVersion: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="apiTokenInput">Api Token</Label>
          <Input
            type="text"
            name="apiTokenInput"
            id="apiTokenInput"
            value={formRequest.apiToken}
            onChange={(e) => {
              setFormRequest({ ...formRequest, apiToken: e.target.value });
            }}
          />
        </FormGroup>
        {putAccountTask.result && (
          <Alert color="primary">
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            {'Account created, '}
            <Link to={`/accounts/${putAccountTask.result.data.id}`}>view it</Link>
            {', submit another or '}
            <Link to="/accounts">go back to the account list</Link>
          </Alert>
        )}
        <Row className="align-items-center mt-5">
          <Col xs="auto">
            <Button
              color="primary"
              disabled={putAccountTask.started && putAccountTask.pending}
              onClick={() => {
                putAccountTask.start({
                  data: formRequest,
                });
              }}
            >
              {putAccountTask.started && putAccountTask.pending && (
                <Spinner size="sm" className="mr-2" />
              )}
              Create Account
            </Button>
          </Col>
          <Col>
            {putAccountTask.error && (
              <InlineErrorMessageNoBorder
                title="Failed to create account"
                message="Please try again and if the problem persists please consult your system administrator"
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
