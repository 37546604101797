import React, { useMemo, useState, useEffect } from 'react';
import { Container, Spinner, Row, Col, Button } from 'reactstrap';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import axios, { AxiosResponse } from 'axios';
import { DataSource } from '@elit-integration/dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { findIndex } from 'lodash';

import { Link } from 'react-router-dom';
import { SimpleMessage } from './simple-message/simple-message';

import { useAuth0 } from '../auth';
import { FeedRow } from './feed-row';

export const Feeds: React.FC = (): JSX.Element => {
  const { user } = useAuth0();
  const [feeds, setFeeds] = useState<DataSource[]>([]);
  const getFeedsMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/datasources`,
    };
  }, []);

  const getFeedsTask = useAsyncTaskAxios<AxiosResponse<DataSource[]>>(axios, getFeedsMemo);

  useAsyncRun(getFeedsTask);

  useEffect(() => {
    if (getFeedsTask.result) {
      setFeeds(getFeedsTask.result.data);
    }
  }, [getFeedsTask.result]);
  if (!user) {
    return <></>;
  }
  return (
    <>
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row className="mb-1 align-items-center">
            <Col lg={12}>
              <h3 className="mb-0">Feeds</h3>
            </Col>
            <Col className="text-right">
              <Button color="primary" tag={Link} to="/feeds/add">
                Add Feed
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        {getFeedsTask.pending && (
          <SimpleMessage
            className="mb-5"
            icon={
              <Spinner
                role="progressbar"
                color="primary"
                className="d-block mx-auto"
                style={{ width: '3rem', height: '3rem' }}
              />
            }
            title="Retrieving feeds..."
          />
        )}
        {getFeedsTask.result && feeds.length === 0 && (
          <SimpleMessage
            className="mb-5"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
            title="No feeds configured"
          />
        )}
        {getFeedsTask.result && feeds.length > 0 && (
          <>
            {feeds.map((x) => (
              <FeedRow
                feed={x}
                onDelete={(deletedId: string) => {
                  const index = findIndex(feeds, (y) => y.id === deletedId);
                  setFeeds([...feeds.slice(0, index), ...feeds.slice(index + 1)]);
                }}
              />
            ))}
          </>
        )}
      </Container>
    </>
  );
};
