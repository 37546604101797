import React from 'react';
import {
  Container,
  Navbar,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faCaretDown, faChevronLeft, faCog } from '@fortawesome/pro-solid-svg-icons';
import { useAuth0 } from '../auth';
import caelogo from '../assets/CE_Master_Logo_RGB.svg';

export const NavBar = (): JSX.Element => {
  const { logout, isAuthenticated, user } = useAuth0();
  const location = useLocation();

  const isLandingPage = location.pathname === '/';

  return (
    <div className="nav-container">
      <Navbar color="faded" className="border-bottom py-3" light expand="md">
        <Container>
          <Row className="w-100 align-items-center no-gutters">
            {!isLandingPage && (
              <Col>
                <Link to="/" onClick={() => sessionStorage.clear()}>
                  <span>
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-2 text-secondary" />
                    <span className="font-weight-bold text-secondary">
                      Back
                      <span className="d-none d-md-inline"> to job list</span>
                    </span>
                  </span>
                </Link>
              </Col>
            )}
            <Col
              className={`${
                !isLandingPage ? 'navbar-logo-container--sub-page' : ''
              } navbar-logo-container font-weight-bold text-black text-left`}
            >
              <img src={caelogo} height="36" alt="Cambridge Assessment English" />
              {isLandingPage && (
                <div className="d-none d-lg-block site-heading">ELiT integration POC</div>
              )}
            </Col>

            <Col className="text-right">
              <Nav
                className="ml-auto align-items-end align-items-md-center  justify-content-end"
                navbar
              >
                {isAuthenticated && user && (
                  <>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle
                        nav
                        className="ml-3 p-0 nav-toggle"
                        data-testid="header-toggle"
                      >
                        <div className="d-inline-block align-middle">
                          <div className="font-weight-bold  text-color333" />
                        </div>
                        <FontAwesomeIcon
                          className="d-inline-block ml-3 align-middle text-color333"
                          icon={faCaretDown}
                        />
                      </DropdownToggle>

                      <DropdownMenu right className="mt-3">
                        <Link className="no-text-hover" to="/accounts">
                          <DropdownItem
                            id="qsManageAccounts"
                            className="text-left py-3 font-weight-bold mr-4"
                          >
                            <FontAwesomeIcon icon={faCog} className="mr-3" />
                            Manage Accounts
                          </DropdownItem>
                        </Link>
                        <Link className="no-text-hover" to="/feeds">
                          <DropdownItem
                            id="qsManageFeeds"
                            className="text-left py-3 font-weight-bold mr-4"
                          >
                            <FontAwesomeIcon icon={faCog} className="mr-3" />
                            Manage Feeds
                          </DropdownItem>
                        </Link>
                        <DropdownItem
                          id="qsLogoutBtn"
                          className="text-left text-danger py-3 font-weight-bold mr-4 "
                          onClick={() => logout({ returnTo: window.location.origin })}
                        >
                          <FontAwesomeIcon icon={faPowerOff} className="mr-3" />
                          Log out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                )}
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  );
};
