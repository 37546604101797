import React from 'react';

import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { MessagePropsBasic } from './simple-message';
import { BackBox } from './subcomponents/back-box';

export interface ErrorMessagProps extends MessagePropsBasic {
  /** Show a link to refresh the page */
  allowPageRefresh?: boolean;
}

export const SimpleErrorMessage: React.FC<ErrorMessagProps> = ({
  title,
  message,
  className,
  allowPageRefresh,
  children,
}): JSX.Element => (
  <BackBox className={className}>
    <Row className="my-6">
      <Col className="text-center">
        <FontAwesomeIcon
          className="text-danger mb-42"
          style={{ fontSize: '28px' }}
          icon={faExclamationTriangle}
        />
        <div className="text-danger font-weight-bold mb-1 h5">{title}</div>
        <p className="">{message}</p>
        {allowPageRefresh && (
          <a href={window.location.href} className="text-secondary font-weight-bold mt-45">
            <u>Refresh page</u>
          </a>
        )}
        {children}
      </Col>
    </Row>
  </BackBox>
);
