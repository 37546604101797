import { AxiosResponse } from 'axios';

export const isNotFound = (axiosResponse: AxiosResponse | undefined): boolean => {
  return !!axiosResponse && axiosResponse.status === 404;
};

export const isBadRequest = (axiosResponse: AxiosResponse | undefined): boolean => {
  return !!axiosResponse && axiosResponse.status === 400;
};

export const isHighTraffic = (axiosResponse: AxiosResponse | undefined): boolean => {
  return !!axiosResponse && axiosResponse.status === 429;
};

export const isUnAuthenticated = (axiosResponse: AxiosResponse | undefined): boolean => {
  return !!axiosResponse && axiosResponse.status === 401;
};

export const isForbidden = (axiosResponse: AxiosResponse | undefined): boolean => {
  return !!axiosResponse && axiosResponse.status === 403;
};
