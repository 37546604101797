import React, { HTMLAttributes, useState, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { TextItem } from '@elit-integration/dtos';
import * as convert from 'xml-js';
import { unescape } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHourglass } from '@fortawesome/pro-regular-svg-icons';

import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { LabelledVal } from './job-row';

const TextRow: React.FC<
  { textRequest: TextItem; allwaysShow: boolean } & HTMLAttributes<HTMLDivElement>
> = ({ textRequest, allwaysShow = false, className }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(allwaysShow);

  const toggle = (): void => setIsOpen(!isOpen);
  const qti = useMemo(() => {
    if (!textRequest.questionText.startsWith('<?xml')) {
      return undefined;
    }
    const itemBody = convert
      .xml2js(textRequest.questionText)
      .elements.find((elt: convert.Element) => elt.name === 'assessmentItem');
    if (!itemBody) return undefined;
    const divIn = itemBody.elements
      .find((elt: convert.Element) => elt.name === 'itemBody')
      .elements.find((elt: convert.Element) => elt.name === 'div');
    if (!divIn) return undefined;

    const xml = convert.js2xml(divIn);
    return { __html: unescape(xml) };
  }, [textRequest]);
  return (
    <>
      <Card
        onClick={!allwaysShow ? toggle : () => {}}
        className={`transition-none bg-light border-0 rounded-0 ${
          !allwaysShow ? 'pointer' : ''
        } ${className}`}
        data-testid={`job-${textRequest.id}`}
      >
        <CardBody className="px-42 pt-42 pb-4">
          <Row className="align-items-center">
            <Col className="h4 text-dark mb-0" xs="auto">
              <FontAwesomeIcon fixedWidth icon={textRequest.result ? faCheckCircle : faHourglass} />
            </Col>
            {qti && (
              <>
                <Col xs="auto">
                  <UncontrolledDropdown>
                    <DropdownToggle caret className="bg-primary font-weight-bold border-0">
                      QTI
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className="px-3 py-2"
                        onClick={() => navigator.clipboard.writeText(textRequest.questionText)}
                      >
                        <FontAwesomeIcon icon={faCopy} className="mr-3" />
                        Copy QTI to clipboard
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
                <Col className="h5  text-dark mb-0" dangerouslySetInnerHTML={qti} />
              </>
            )}
            {!qti && (
              <Col className="h5 font-weight-bold  text-dark mb-0">{textRequest.questionText}</Col>
            )}
            <Col xs="auto">
              <LabelledVal
                label="prompt relevance"
                val={
                  textRequest.result && textRequest.result.promptRelevance
                    ? textRequest.result.promptRelevance.toString()
                    : '-'
                }
              />
            </Col>
            <Col xs="auto">
              <LabelledVal
                label="score"
                val={
                  textRequest.result ? textRequest.result.overallScore.toFixed(2).toString() : '-'
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Collapse isOpen={isOpen}>
        <Card className="border-0 mt-0 pt-0">
          <CardBody>
            {textRequest.result && textRequest.result.textStats && (
              <Row className="mb-5 justify-content-end">
                {textRequest.result.textStats.r1 !== undefined && (
                  <Col xs="auto" className="px-3">
                    <LabelledVal
                      label="r1"
                      val={textRequest.result.textStats.r1.toFixed(3).toString()}
                    />
                  </Col>
                )}
                {textRequest.result.textStats.r2 !== undefined && (
                  <Col xs="auto" className="px-3">
                    <LabelledVal
                      label="r2"
                      val={textRequest.result.textStats.r2.toFixed(3).toString()}
                    />
                  </Col>
                )}
                {textRequest.result.textStats.r3 !== undefined && (
                  <Col xs="auto" className="px-3">
                    <LabelledVal
                      label="r3"
                      val={textRequest.result.textStats.r3.toFixed(3).toString()}
                    />
                  </Col>
                )}
                {textRequest.result.textStats.lcs !== undefined && (
                  <Col xs="auto" className="px-3">
                    <LabelledVal label="lcs" val={textRequest.result.textStats.lcs.toString()} />
                  </Col>
                )}
                {textRequest.result.textStats.featureCount !== undefined && (
                  <Col xs="auto" className="px-3">
                    <LabelledVal
                      label="feature count"
                      val={textRequest.result.textStats.featureCount.toString()}
                    />
                  </Col>
                )}
                {textRequest.result.textStats.wordCount !== undefined && (
                  <Col xs="auto" className="px-3">
                    <LabelledVal
                      label="word count"
                      val={textRequest.result.textStats.wordCount.toString()}
                    />
                  </Col>
                )}
              </Row>
            )}
            <Row className="mb-5">
              <Col>
                <div style={{ whiteSpace: 'pre-wrap' }}>{textRequest.responseText}</div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
};
export default TextRow;
