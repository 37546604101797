import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { TextJob } from '@elit-integration/dtos';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHourglass } from '@fortawesome/pro-regular-svg-icons';

import { format } from 'date-fns';
import { Size } from '../types';

export const LabelledVal: React.FC<{ label: string; val: string; size?: Size }> = ({
  label,
  val,
  size,
}): JSX.Element => {
  return (
    <>
      <div className="text-right text-secondary font-weight-bold">{label}</div>
      <div className={`${size === 'sm' ? '' : 'h5'} mb-0 text-right text-dark font-weight-bold`}>
        {val}
      </div>
    </>
  );
};

export const JobRow: React.FC<TextJob> = ({
  id,
  textsSubmitted,
  textsCompleted,
  averageScore,
  updatedDate,
}): JSX.Element => {
  return (
    <Link className="no-text-hover" to={`/jobs/${id}`}>
      <Card className="transition-none bg-light border-0 rounded-0" data-testid={`job-${id}`}>
        <CardBody className="px-42 pt-42 pb-4">
          <Row className="align-items-center">
            <Col className="h4 text-dark font-weight-bold mb-0" xs="auto">
              <FontAwesomeIcon
                fixedWidth
                icon={textsCompleted === textsSubmitted ? faCheckCircle : faHourglass}
              />
            </Col>
            <Col className="h5 text-dark font-weight-bold mb-0">{id}</Col>
            <Col xs="auto" className="pr-4">
              <LabelledVal
                label="updated date"
                val={format(new Date(updatedDate), 'dd/MM/yyyy HH:mm')}
              />
            </Col>
            {averageScore && (
              <Col xs="auto" className="pr-4">
                <LabelledVal
                  label={textsSubmitted > 1 ? 'average score' : 'score'}
                  val={`${averageScore?.toFixed(2)}`}
                />
              </Col>
            )}
            <Col xs="auto" className="pr-4">
              <LabelledVal label="text responses" val={`${textsCompleted}/${textsSubmitted}`} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Link>
  );
};
