import React, { useMemo, useState, useEffect } from 'react';
import {
  Container,
  Spinner,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosResponse } from 'axios';
import { TextJob, TextSubmission, ElitAccount } from '@elit-integration/dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { findIndex } from 'lodash';

import { SimpleMessage } from './simple-message/simple-message';
import { InlineErrorMessageNoBorder } from './simple-message/inline-error-message';
import { useAuth0 } from '../auth';
import { User } from '../types';

const getEmptySubmission = (user: User | undefined): TextSubmission => {
  return {
    candidate: {
      id: user ? user.user_id : '',
    },
    testInstance: {
      id: uuidv4(),
    },
    model: '',
    dateSat: new Date(),
    sourceSystemId: 'FORM',
    items: [
      {
        sourceId: uuidv4(),
        responseText: '',
        questionText: '',
      },
    ],
  };
};

export const Submit: React.FC = (): JSX.Element => {
  const { user } = useAuth0();
  const [formRequest, setFormRequest] = useState<TextSubmission>(getEmptySubmission(user));

  const [formValid, setFormValid] = useState(true);
  const getAccountsMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/accounts`,
    };
  }, []);

  const postJobMemo = useMemo(() => {
    return {
      method: 'POST',
      url: `${process.env.REACT_APP_APIDOMAIN}/jobs`,
    };
  }, []);

  const getAccountsTask = useAsyncTaskAxios<AxiosResponse<ElitAccount[]>>(axios, getAccountsMemo);

  const postJobTask = useAsyncTaskAxios<AxiosResponse<TextJob>>(axios, postJobMemo);

  useAsyncRun(getAccountsTask);

  const isValid = useMemo(() => {
    return !formRequest.items.some((x) => x.questionText === '' || x.responseText === '');
  }, [formRequest.items]);

  useEffect(() => {
    // clear the form
    setFormRequest(getEmptySubmission(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postJobTask.result]);

  const apiVersion = useMemo(() => {
    if (getAccountsTask.result && !formRequest.model && getAccountsTask.result.data.length > 0) {
      setFormRequest({ ...formRequest, model: getAccountsTask.result.data[0].id });
    }
    if (getAccountsTask.result) {
      const filtered = getAccountsTask.result.data.filter((x) => x.id === formRequest.model);
      if (filtered.length === 1) {
        return filtered[0].apiVersion;
      }
    }
    return '';
  }, [formRequest, getAccountsTask.result]);

  if (!user) {
    return <></>;
  }
  return (
    <>
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row className="mb-1">
            <Col lg={12}>
              <h3>Submit Text</h3>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        {getAccountsTask.pending && (
          <SimpleMessage
            className="mb-5"
            icon={
              <Spinner
                role="progressbar"
                color="primary"
                className="d-block mx-auto"
                style={{ width: '3rem', height: '3rem' }}
              />
            }
            title="Retrieving config..."
          />
        )}
        {getAccountsTask.result && getAccountsTask.result.data.length === 0 && (
          <SimpleMessage
            className="mb-5"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
            title="No accounts configured"
          />
        )}
        {getAccountsTask.result && getAccountsTask.result.data.length > 0 && (
          <>
            <FormGroup>
              <Label for="modelSelect">Model</Label>
              <Input
                type="select"
                name="select"
                id="modelSelect"
                value={formRequest.model}
                onChange={(e) => {
                  setFormRequest({ ...formRequest, model: e.target.value });
                }}
              >
                {getAccountsTask.result.data.map((x) => (
                  <option key={x.id} label={x.modelName}>
                    {x.id}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="aipVersionSelect">Api Version</Label>
              <div>{apiVersion}</div>
            </FormGroup>
            {formRequest.items.map((x, i) => (
              <Card key={x.sourceId} className="border-0">
                <CardHeader className="font-weight-bold border-0">
                  <Row>
                    <Col>
                      Question
                      {i + 1}
                    </Col>
                    {formRequest.items.length > 1 && (
                      <Col xs="auto">
                        <Button
                          close
                          onClick={() => {
                            const indexOfItemToUpdate = findIndex(
                              formRequest.items,
                              (y) => y.sourceId === x.sourceId
                            );
                            const updatedItems = [
                              ...formRequest.items.slice(0, indexOfItemToUpdate),
                              ...formRequest.items.slice(indexOfItemToUpdate + 1),
                            ];
                            setFormRequest({ ...formRequest, items: updatedItems });
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="prompt">Question Prompt</Label>
                    <Input
                      type="text"
                      name="prompt"
                      id="prompt"
                      value={x.questionText}
                      onChange={(e) => {
                        const indexOfItemToUpdate = findIndex(
                          formRequest.items,
                          (y) => y.sourceId === x.sourceId
                        );
                        const updated = {
                          ...formRequest.items[indexOfItemToUpdate],
                          questionText: e.target.value,
                        };
                        const updatedItems = [
                          ...formRequest.items.slice(0, indexOfItemToUpdate),
                          updated,
                          ...formRequest.items.slice(indexOfItemToUpdate + 1),
                        ];
                        setFormRequest({ ...formRequest, items: updatedItems });
                        if (isValid) {
                          setFormValid(isValid);
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">Response Text</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="exampleText"
                      value={x.responseText}
                      onChange={(e) => {
                        const indexOfItemToUpdate = findIndex(
                          formRequest.items,
                          (y) => y.sourceId === x.sourceId
                        );
                        const updated = {
                          ...formRequest.items[indexOfItemToUpdate],
                          responseText: e.target.value,
                        };
                        const updatedItems = [
                          ...formRequest.items.slice(0, indexOfItemToUpdate),
                          updated,
                          ...formRequest.items.slice(indexOfItemToUpdate + 1),
                        ];
                        setFormRequest({ ...formRequest, items: updatedItems });
                        if (isValid) {
                          setFormValid(isValid);
                        }
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            ))}
            {postJobTask.result && (
              <Alert color="primary">
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                {'Text submitted, '}
                <Link to={`/jobs/${postJobTask.result.data.id}`}>view it</Link>
                {', submit another or '}
                <Link to="/">go back to the job list</Link>
              </Alert>
            )}
            <Row className="align-items-center mt-5">
              <Col xs="auto">
                <Button
                  color="primary"
                  disabled={postJobTask.started && postJobTask.pending}
                  onClick={() => {
                    setFormRequest({
                      ...formRequest,
                      items: [
                        ...formRequest.items,
                        { sourceId: uuidv4(), responseText: '', questionText: '' },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-3" />
                  Add Text
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  color="primary"
                  disabled={postJobTask.started && postJobTask.pending}
                  onClick={() => {
                    if (isValid) {
                      postJobTask.start({
                        data: formRequest,
                      });
                    } else {
                      setFormValid(false);
                    }
                  }}
                >
                  {postJobTask.started && postJobTask.pending && (
                    <Spinner size="sm" className="mr-2" />
                  )}
                  Submit text
                </Button>
              </Col>
              <Col>
                {postJobTask.error && (
                  <InlineErrorMessageNoBorder
                    title="Failed to create job"
                    message="Please try again and if the problem persists please consult your system administrator"
                  />
                )}
                {!formValid && (
                  <InlineErrorMessageNoBorder
                    title="Form invalid"
                    message="Please ensure each question has a question text and response text"
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};
