import React, { useMemo, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Spinner } from 'reactstrap';
import { ElitAccount } from '@elit-integration/dtos';

import axios, { AxiosResponse } from 'axios';
import { useAsyncTaskAxios } from 'react-hooks-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { LabelledVal } from './job-row';

export interface AccountRowProps {
  account: ElitAccount;
  onDelete: (accountId: string) => void;
}

export const AccountRow: React.FC<AccountRowProps> = ({ account, onDelete }): JSX.Element => {
  const { id, name, modelName, endpoint, apiVersion } = account;
  const deleteAccountMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/accounts/${id}`,
      method: 'DELETE',
    };
  }, [id]);

  const deleteAccountTask = useAsyncTaskAxios<AxiosResponse<void>>(axios, deleteAccountMemo);

  useEffect(() => {
    if (deleteAccountTask.result) {
      onDelete(id);
    }
  }, [onDelete, deleteAccountTask.result, id]);

  return (
    <Card className="transition-none bg-light border-0 rounded-0" data-testid={`job-${id}`}>
      <CardBody className="px-42 pt-42 pb-4">
        <Row className="align-items-center">
          <Col className="text-dark font-weight-bold ">
            <div className="h5 mb-0 font-weight-bold ">
              {name}
              {modelName !== '' && modelName !== name && ` (${modelName}) `}
            </div>
            <div className="text-secondary">
              Account Id:
              {id}
            </div>
          </Col>
          <Col xs="auto" className="pr-4">
            <LabelledVal label="Endpoint" val={endpoint} size="sm" />
          </Col>
          <Col xs="auto" className="pr-4">
            <LabelledVal label="API Version" val={apiVersion} />
          </Col>
          <Col xs="auto">
            {deleteAccountTask.error && (
              <FontAwesomeIcon icon={faExclamationTriangle} title="Failed to delete account" />
            )}
            {deleteAccountTask.pending && deleteAccountTask.started && <Spinner size="sm" />}
            {!deleteAccountTask.started && (
              <Button
                color="link"
                onClick={() => {
                  deleteAccountTask.start();
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
