import React, { useState, useEffect, useContext, FC, useCallback } from 'react';
import createAuth0Client, { Auth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js';
import axios from 'axios';
// eslint-disable-next-line import/no-unresolved

import { useHistory } from 'react-router';
import { AuthContextProps, User, AuthConfig, AppState } from './types';

export const Auth0Context = React.createContext<AuthContextProps>({
  isAuthenticated: false,
  user: undefined,

  handleRedirectCallback: () => {
    throw new Error('Not implemented');
  },
  loginWithRedirect: () => {
    throw new Error('Not implemented');
  },
  loginWithPopup: () => {
    throw new Error('Not implemented');
  },
  getIdTokenClaims: () => {
    throw new Error('Not implemented');
  },
  getTokenSilently: () => {
    throw new Error('Not implemented');
  },
  getTokenWithPopup: () => {
    throw new Error('Not implemented');
  },
  logout: () => {
    throw new Error('Not implemented');
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapUser = (aoUser: any, roleUrl: string): User => {
  const roles: string[] = roleUrl in aoUser ? aoUser[roleUrl] : [];

  const mappedUser: User = aoUser;
  mappedUser.roles = roles;
  mappedUser.user_id = mappedUser.sub;

  // If no roles, check if a single role exists
  if (mappedUser.roles.length === 0) {
    const singleRoleUrl = roleUrl.replace(/roles$/, 'role');
    if (singleRoleUrl in aoUser) {
      const role = aoUser[singleRoleUrl];
      mappedUser.roles = typeof role === 'string' ? [role] : role;
    }
  }

  return mappedUser;
};

export const useAuth0 = (): AuthContextProps => useContext(Auth0Context);
export const Auth0Provider: FC<AuthConfig> = ({ children, ...rest }): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const history = useHistory();

  const onRedirectCallback = useCallback(
    (appState: AppState): void => {
      history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
    },
    [history]
  );
  const [auth0Client, setAuth0] = useState<Auth0Client | undefined>(undefined);

  const [loading, setLoading] = useState(true);
  const [, setPopupOpen] = useState(false);
  useEffect((): void => {
    const initAuth0 = async (): Promise<void> => {
      try {
        const auth0FromHook = await createAuth0Client({
          ...rest,
        });
        setAuth0(auth0FromHook);

        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const isAuthenticatedNow = await auth0FromHook.isAuthenticated();

        setIsAuthenticated(isAuthenticatedNow);

        if (isAuthenticatedNow) {
          const aoUser = await auth0FromHook.getUser();
          const userToSet = mapUser(aoUser, rest.role_url);

          setUser(userToSet);
        }

        setLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        const newA0Client = new Auth0Client({ ...rest });
        newA0Client.logout({ returnTo: window.location.origin });
      }
    };
    initAuth0();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRedirectCallback]);

  useEffect(() => {
    if (auth0Client) {
      const authInterceptor = axios.interceptors.request.use(async (request) => {
        if (request.url?.startsWith(process.env.REACT_APP_APIDOMAIN ?? 'REACT_APP_APIDOMAIN')) {
          try {
            const newToken = await auth0Client.getTokenSilently({ ...rest });
            request.headers.Authorization = `Bearer ${newToken}`;
          } catch (e) {
            if (e.error === 'access_denied') {
              auth0Client.logout({
                returnTo: `${window.location.origin}?error=${e.error}`,
              });
            }
            if (e.error === 'login_required') {
              // would be nicer to show a little popup saying session timedout and login button but at least it works now
              auth0Client.loginWithRedirect({
                redirect_uri: window.location.origin,
                appState: { targetUrl: window.location.pathname },
              });
            }
          }
        }
        return request;
      });
      return function cleanup() {
        axios.interceptors.request.eject(authInterceptor);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Client, rest]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loginWithPopup = async (params = {}): Promise<void> => {
    setPopupOpen(true);
    try {
      if (auth0Client) {
        await auth0Client.loginWithPopup(params);
      }
    } finally {
      setPopupOpen(false);
    }
    const theUser = auth0Client ? await auth0Client.getUser() : undefined;
    setUser(theUser);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async (): Promise<RedirectLoginResult> => {
    setLoading(true);

    const result = auth0Client ? await auth0Client.handleRedirectCallback() : undefined;

    const theUser = auth0Client ? await auth0Client.getUser() : undefined;
    setLoading(false);
    setIsAuthenticated(true);
    setUser(theUser);
    return result ?? ({} as RedirectLoginResult);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        loginWithPopup,

        getIdTokenClaims: auth0Client
          ? (p) => auth0Client.getIdTokenClaims(p)
          : () => {
              throw new Error('Not implemented');
            },
        loginWithRedirect: auth0Client
          ? (p) => auth0Client.loginWithRedirect(p)
          : () => {
              throw new Error('Not implemented');
            },
        getTokenSilently: auth0Client
          ? (p) => auth0Client.getTokenSilently(p)
          : () => {
              throw new Error('Not implemented');
            },
        getTokenWithPopup: auth0Client
          ? (p) => auth0Client.getTokenWithPopup(p)
          : () => {
              throw new Error('Not implemented');
            },
        logout: auth0Client
          ? (p) => auth0Client.logout(p)
          : () => {
              throw new Error('Not implemented');
            },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
