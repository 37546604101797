import React, { useMemo, useState, useEffect } from 'react';
import { Container, Spinner, FormGroup, Label, Input, Button, Row, Col, Alert } from 'reactstrap';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import axios, { AxiosResponse } from 'axios';
import { ElitAccount, DataSource } from '@elit-integration/dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { InlineErrorMessageNoBorder } from './simple-message/inline-error-message';
import { useAuth0 } from '../auth';

export const FeedForm: React.FC = (): JSX.Element => {
  const { user } = useAuth0();
  const [formRequest, setFormRequest] = useState<DataSource>({
    id: '',
    name: '',
  });
  const getAccountsMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/accounts`,
    };
  }, []);

  const getAccountsTask = useAsyncTaskAxios<AxiosResponse<ElitAccount[]>>(axios, getAccountsMemo);

  useAsyncRun(getAccountsTask);

  const putFeedMemo = useMemo(() => {
    return {
      method: 'PUT',
      url: `${process.env.REACT_APP_APIDOMAIN}/datasources`,
    };
  }, []);

  const putFeedTask = useAsyncTaskAxios<AxiosResponse<DataSource>>(axios, putFeedMemo);

  useEffect(() => {
    // clear the form
    setFormRequest({
      id: '',
      name: '',
      defaultAccountId: undefined,
      defaultModelName: undefined,
    });
  }, [putFeedTask.result]);

  useEffect(() => {
    if (getAccountsTask.result) {
      const filtered = getAccountsTask.result.data.filter(
        (x) => x.id === formRequest.defaultAccountId
      );
      if (filtered.length === 1 && formRequest.defaultModelName !== filtered[0].modelName) {
        setFormRequest({ ...formRequest, defaultModelName: filtered[0].modelName });
      }
    }
  }, [formRequest, getAccountsTask.result]);

  if (!user) {
    return <></>;
  }
  return (
    <>
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row className="mb-1">
            <Col lg={12}>
              <h3>Add Feed</h3>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <FormGroup>
          <Label for="nameInput">Name</Label>
          <Input
            type="text"
            name="nameInput"
            id="nameInput"
            value={formRequest.name}
            onChange={(e) => {
              setFormRequest({ ...formRequest, name: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="modelSelect">Default Model</Label>
          <Input
            type="select"
            name="select"
            id="modelSelect"
            value={formRequest.defaultAccountId}
            onChange={(e) => {
              setFormRequest({ ...formRequest, defaultAccountId: e.target.value });
            }}
          >
            <option key="null" value="" className="text-secondary">
              None selected
            </option>
            {getAccountsTask.result &&
              getAccountsTask.result.data.map((x) => (
                <option key={x.id} label={`${x.modelName} - (${x.id})`}>
                  {x.id}
                </option>
              ))}
          </Input>
        </FormGroup>
        {putFeedTask.result && (
          <Alert color="primary">
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            {'Account created, '}
            <Link to={`/feeds/${putFeedTask.result.data.id}`}>view it</Link>
            {', submit another or '}
            <Link to="/feeds">go back to the feed list</Link>
          </Alert>
        )}
        <Row className="align-items-center mt-5">
          <Col xs="auto">
            <Button
              color="primary"
              disabled={
                (putFeedTask.started && putFeedTask.pending) || !formRequest.defaultAccountId
              }
              onClick={() => {
                putFeedTask.start({
                  data: formRequest,
                });
              }}
            >
              {putFeedTask.started && putFeedTask.pending && <Spinner size="sm" className="mr-2" />}
              Create Feed
            </Button>
          </Col>
          <Col>
            {putFeedTask.error && (
              <InlineErrorMessageNoBorder
                title="Failed to create feed"
                message="Please try again and if the problem persists please consult your system administrator"
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
