import React, { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route } from 'react-router';
import { Auth0Provider } from './auth';

import { RoutesWithHeader } from './components/routes-with-header';

const App: FC = (): JSX.Element => {
  return (
    <Router>
      <Auth0Provider
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        domain={process.env.REACT_APP_AUTH_DOMAIN || ''}
        audience={process.env.REACT_APP_AUTH_AUDIENCE || ''}
        client_id={process.env.REACT_APP_AUTH_CLIENT_ID || ''}
        role_url={process.env.REACT_APP_AUTH_ROLE_URL || ''}
      >
        <Route component={RoutesWithHeader} />
      </Auth0Provider>
    </Router>
  );
};

export default App;
