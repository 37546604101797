import React, { useMemo } from 'react';

interface TabContentLabelProps {
  isActive?: boolean;
  label: string;
}

export const TabContentLabel: React.FC<TabContentLabelProps> = ({
  isActive = false,
  label,
}): JSX.Element => {
  const syleClasses = useMemo(() => {
    if (isActive) {
      return 'bg-primary text-white';
    }
    return 'bg-lightButNotTooLight text-dark';
  }, [isActive]);

  return (
    <div
      data-testid={`tab-${label}`}
      className={`${syleClasses} rounded font-weight-bold py-2 px-25 position-relative `}
    >
      <div data-test-id="syllabus-filter" className="text-center">
        <div className="font-larger">{label}</div>
      </div>
    </div>
  );
};
